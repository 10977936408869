import tw from 'twin.macro'
import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'

const Wrapper = tw.div`
  flex items-center justify-center flex-col h-screen
`

const Main = tw.div`
  p-6 bg-gray-100 rounded-lg shadow-2xl
`

const Heading = tw.h1`
  text-2xl text-gray-500 uppercase
`

const Text = tw.p`
  text-xl text-gray-700
`

const SignupThanks = () => {
  return (
    <Layout>
      <Wrapper>
        <SEO title="Signup Thanks" />
        <Main>
          <Heading>
            Thanks for subscribing. Please check your email for the confirmation
            link.
          </Heading>
          <Text>
            This step makes sure that someone other than you isn't just signing
            you up for content without you consenting.
          </Text>
        </Main>
      </Wrapper>
    </Layout>
  )
}

export default SignupThanks
